@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

$darkgrey:#595959;
$grey:#9D9D9D;
$lightgrey:#DDDDDD;
$black:#000000;
$blue:#2890C6;
$green:#95D828;
$pink:#FF728E;
.no-scroll{
  overflow: auto;
  @media (max-width: 828px) {
    overflow: hidden;
  }
}

.desctop {
  position: relative;
  z-index: 5;
  cursor: pointer;
  @media (max-width: 828px) {
    display: none !important;
  }
}

.mobile {
  display: none !important;
  @media (max-width: 828px) {
    display: block !important;
  }
}

.float-img {
  position: absolute;
}

.wrapper{
  font-family: 'Syne', sans-serif;
}

.main--container{
  max-width: 1712px;
  margin: 0 auto;
  padding: 0 30px;
  @media (max-width: 1200px) {
    max-width: 1052px;
  }
  @media (max-width: 1052px) {
    max-width: 828px;
  }
  @media (max-width: 828px) {
    max-width: 636px;
  }
}

.content--container{
  padding: 0 133px 0 137px;
  @media (max-width: 1712px) {
    padding: 0 80px;
  }
  @media (max-width: 1615px) {
    padding: 0 70px;
  }
  @media (max-width: 1615px) {
    padding: 0 50px;
  }
}

.nothing {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

//Header===================
.header{
  margin-bottom: 50px;
  z-index: 5;
  @media (max-width: 828px) {
    margin-bottom: 38px;
  }
  &--container{
    display: flex;
    align-items: center;
    padding-top: 37px;
    @media (max-width: 828px){
      padding-top: 23px;
    }
  }
  &--logo{
    flex: 0 0 50px;
    height: 50px;
    margin-right: 24px;
    &__img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &--name{
    font-weight: 700;
    font-size: 25px;
    @media (max-width: 450px) {
      font-size: 18px;
    }
  }
  &--menu__burger{
    display: none;
    margin-left: auto;
    @media (max-width: 828px) {
      display: block;
      cursor: pointer;
      flex: 0 0 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid $lightgrey;
      position: relative;
      &:after, span{
        content: "";
        position: absolute;
        height: 2px;
        background: #ABABAB;
        transition: all 0.2s ease;
      }
      &:after{
        width: 29px;
        top: 36%;
        left: 50%;
        transform: translateX(-50%);
      }
      span{
        width: 17px;
        top: 50%;
        left: 10px;
      }
      &_active{
        &:after{
          width: 29px;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) rotate(45deg);
        }
        span{
          width: 29px;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
  }
  &--menu__wrapper{
    margin-left: 92px;
    @media (max-width: 828px) {
      overflow: auto;
      margin-left: 0;
      max-width: 100%;
      transition: all 0.2s ease;
      position: absolute;
      top: 85px;
      left: -100%;
      right: 0;
      bottom: 0;
      min-height: 100%;
      z-index: 100;
      background: #FFFFFF;
      padding-top: 30px;
      &_active{
        left: 0;
      }
    }
  }
  &--menu{
    font-weight: 600;
    font-size: 20px;
    @media (max-width: 828px) {
      min-height: calc(100% + 30px);
    }
    &__list{
      display: flex;
      align-items: center;
      @media (max-width: 828px){
        flex-direction: column;
        align-items: start;
        padding: 0 30px;
        margin: 0 auto;
        max-width: 636px;
      }
    }
    &__item{
      color: #858585;
      margin-right: 44px;
      &:last-child{
        margin-right: 0;
      }
      @media (max-width: 828px){
        color: #000000;
        padding: 23px 0 25px;
        border-bottom: 2px solid $lightgrey;
        font-size: 32px;
        margin-right: 0;
        width: 100%;
        &:first-child{
          border-top: 2px solid $lightgrey;
        }
      }
    }
    .active{
      color: $pink;
    }
  }

  &__btn {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    padding: 17px 47px 24px;
    background: #95D828;
    backdrop-filter: blur(6.5px);
    border-radius: 12px;
    transition: all 0.2s ease;
    margin-left: auto;

    &:hover{
      color: #83C814;
      background: #E6FFBE;
    }

    &.mobile {
      margin-left: 0;
    }

    &:active {
      transform: scale(0.95);
    }
    @media (max-width: 400px) {
      padding: 16px 33px 22px;
    }
  }

  &__connected {
    font-weight: 700;
    padding:17px 22px 24px ;
    font-size: 20px;
    color: #8f8f8f;
    border: 1px solid #8f8f8f;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(6.5px);
    border-radius: 12px;
    position: relative;
    margin-left: auto;

    &::before {
      content: "Disconnect";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.1s;
    }
    
    &:hover {
      &::before {
        opacity: 1;
      }
    }

    @media (max-width: 1300px) {
      margin-bottom: 180px;
    }
    @media (max-width: 500px) {
      font-size: 18px;
    }
    @media (max-width: 455px) {
      font-size: 16px;
    }
  }
}


//Connect-Wallet=============
.connect--wallet{
  &__body{
    padding: 33px 103px 33px 113px ;
    min-height: 291px;
    border-radius: 20px;
    position: relative;
    background: url("./assets/connect-wallet/bg_main.png") center / cover no-repeat;
    @media (max-width: 1300px) {
      padding: 33px 80px 33px 80px ;
      min-height: 350px;
    }
    @media (max-width: 828px) {
      min-height: 400px;
    }
    @media (max-width: 455px) {
      min-height: 350px;
    }
    @media (max-width: 500px) {
      padding: 20px 33px 33px 33px;
    }
    .body__bg{
      position: absolute;
      right: 0;
      top: -75px;
      width: 1000px;
      height: 430px;
      overflow:hidden;
      z-index: 0;
      &--img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (max-width: 1052px) {
        display: none;
      }
    }
  }
  &__body_active{
    background: url("./assets/connect-wallet/active_bg.png") center / cover no-repeat;

  }
  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    @media (max-width: 1300px) {
      flex-direction: column;
      align-items: start;
    }
  }
  &__title{
    font-weight: 800;
    font-size: 52px;
    color: #FFFFFF;
    @media (max-width: 1300px) {
      margin-bottom: 20px;
    }
    @media (max-width: 1052px) {
      font-size: 46px;
    }
    @media (max-width: 455px) {
      font-size: 32px;
    }

  }
}

//ProfileContent==============
.main--block{
  display: flex;
  @media (max-width: 828px) {
    flex-direction: column;
  }
  .profile{
    position:relative;
    margin-right: 54px;
    margin-top: -140px;
    flex: 0 0 400px;
    max-width: 400px;
    min-height: 600px;
    @media (max-width: 1200px) {
      flex: 0 0 350px;
    }
    @media (max-width: 1052px) {
      margin-right: 30px;
    }
    @media (max-width: 828px) {
      width: 440px;
      margin: 0 auto;
      margin-top: -140px;
      flex: 0 0 auto;
    }
    @media (max-width: 530px) {
      max-width: 280px;
      min-height: 450px;
    }
    &--body{
      &__menu{
        display: flex;
        position: absolute;
        &--item{
          flex: 1 1 33%;
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          height: 77px;
          color: rgba(255, 255, 255, 0.8);
          padding: 14px 23px 19px;
          background: rgba(99, 99, 99, 0.34);
          backdrop-filter: blur(213.624px);
          border-radius: 10px;
          border: 1px solid #FFFFFF;
          margin-right: 16px;
          transition: all 0.2s ease;
          cursor: pointer;
          &:last-child{
            margin-right: 0;
          }
          @media (max-width: 1200px) {
            padding: 14px 10px 19px;
            margin-right: 8px;
          }
          @media (max-width: 828px) {
            padding: 14px 23px 19px;
            margin-right: 16px;
          }
          @media (max-width: 530px) {
            font-size: 16px;
            padding: 14px 10px 19px;
            margin-right: 8px;
          }
          &:not([disabled]) {
            &:hover{
              background: rgba(162, 162, 162, 0.34);
              backdrop-filter: blur(213.624px);
            }
          }
          &:disabled {
            opacity: 0.9;
            cursor: not-allowed;
          }
        }
        &--item_active{
          background: black !important;
          height: 152px;
        }
      }
      &__information{
        padding-top: 100px;
        width: 100%;
        .profile__img-wrapper{
          width: 100%;
          height: 300px;
          position: relative;
          overflow: hidden;
          border-radius: 16px;
          @media (max-width: 828px) {
            height: 350px;
          }
          @media (max-width: 530px) {
            height: 215px;
          }
          .profile__img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            filter: grayscale(0.85);
            transition: all 0.1s;

            &.colour {
              filter: grayscale(0);
            }
          }
        }
        .person__inf{
          border: 1px solid #DDDDDD;
          border-radius: 16px;
          margin-top: -40px;
          padding-top: 40px;
          background: #FFFFFF;
          &--unconnected{
            display: flex;
            padding-top: 15px;
            padding-bottom: 31px;
            align-items: center;
            font-weight: 500;
            max-width: 287px;
            margin-left: 40px;
            span{
              font-size: 54px;
              color: $pink;
              margin-right: 23px;
              @media (max-width: 530px) {
                font-size: 52px;
              }
            }
            div{
              font-size: 22px;
              color: $darkgrey;
              @media (max-width: 530px) {
                font-size: 18px;
              }
            }
          }

          &--connected{
            margin: 0 16px;
            padding: 16px 0;
            .mint--btn{
              display: inline-block;
              text-align: center;
              width: 100%;
              background: #95D828;
              border-radius: 16px;
              padding: 24px 0 37px;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #FFFFFF;
              position: relative;
              transition: all 0.1s;
              
              &:disabled {
                background: #4f4f4f;
                cursor: not-allowed;

                &:hover {
                  background: #4f4f4f99;
                  &::before {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 1;
                    backdrop-filter: blur(4px);
                  }
                }

                &::before {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              &#not_wl {
                &::before {
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  border-radius: 16px;
                  content: "You're not in white list";
                  padding-bottom: 10px;
                  color: #FFF;
                  display: none;
                  opacity: 0;
                  vertical-align: center;
                  text-align: center;
                  transition: all 0.1s;
                }
              }

              &#wl {
                &::before {
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  border-radius: 16px;
                  content: "Please connect wallet";
                  padding-bottom: 10px;
                  color: #FFF;
                  display: none;
                  opacity: 0;
                  vertical-align: center;
                  text-align: center;
                  transition: all 0.1s;
                }
              }

              &:not(:disabled) {
                &:hover {
                  transform: scale(0.95);
                }
  
                &:active {
                  transform: scale(0.91);
                }
              }
            }
          }

          &--pass{
            margin: 0 41px 0 30px;
            padding: 22px 0;
          }
          .pass--information{
            display: flex;
            align-items: center;
            &__column--first{
              flex: 0 0 65%;
              font-weight: 600;
              border-right:1px solid $lightgrey;
            }
            &__data{
              margin-bottom: 11px;
              font-weight: 600;
              &:last-child{
                margin-bottom: 0;
              }
              .data--title{
                font-size: 16px;
                color: #858585;
              }
              .data--content{
                font-size: 28px;
                &_pink{
                  color: $pink;
                }
                &_small{
                  font-size: 22px;
                  font-weight: 500;
                }
              }

            }
            &__column--second{
              flex: 1 1 auto;
              margin-left: 22px;
            }
          }
        }
      }
    }
  }
  .nft--list{
    flex: 1 1 auto;
    &__body{
      margin-top: 46px;
      @media (max-width: 1052px) {
        margin-top: 20px;
      }
    }
    .search--block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 18px;
      color: #858585;
      margin-bottom: 31px;
      &__sort{
        flex: 0 0 328px;
        margin-right: 30px;
        @media (max-width: 1200px) {
          flex: 0 0 250px;
          margin-bottom: -15px;
        }
        @media (max-width: 828px) {
          flex: 0 0 250px;
          margin-right: 50px;
          margin-top: 10px;
          margin-bottom: 0;
        }
        @media (max-width: 530px) {
          flex: 0 0 180px;
          margin-right: 80px;
        }
        .sort--selector{
          width: 100%;
          position: relative;
          &__header{
            display: none;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;
            border: 1px solid #E1E1E1;
            border-radius: 10px;
            @media (max-width: 1200px) {
              display: flex;
              margin-bottom: 10px;
            }
            @media (max-width: 530px) {
              font-size: 14px;
              padding: 10px 18px;
            }
            div{
              font-weight: 500;
              font-size: 22px;
              color: #2890C6;
              margin-right: 10px;
              @media (max-width: 828px) {
                font-size: 18px;
              }
              @media (max-width: 530px) {
                font-size: 14px;
              }
            }
            img{
              width: 9px;
              height: 5px;
              object-fit: contain;
              transition: all 0.3s ease-in-out;
            }
            .arrow_opened{
              transform: rotate(180deg);
            }
          }
          &__body{
            width: 100%;
            @media (max-width: 1200px) {
              position: absolute;
              z-index: 20;
              max-height: 0;
              transition: all 0.3s ease-in-out;
              overflow: hidden;
            }
            &--options{
              display: flex;
              @media (max-width: 1200px) {
                flex-direction: column;
                border: 1px solid #E1E1E1;
                border-radius: 10px;
                font-size: 22px;
                padding: 11px 20px;
                color: #858585;
                background: #FFFFFF;
              }
              @media (max-width: 828px) {
                font-size: 18px;
              }
              @media (max-width: 530px) {
                font-size: 14px;
                padding: 10px 18px;
              }
            }
            &_active{
              max-height: 150px;
            }
          }
        }
        &--type{
          flex: 1 1 auto;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          &:first-child{
            margin-right: 40px;
            @media (max-width: 1200px) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
          &:before{
            content: "";
            transition: all 0.3s ease;
            display: block;
            position: absolute;
            bottom: -6px;
            width: 0;
            height: 2px;
          }
        }
        .active--sort{
          color: $blue;
          &:before{
            width: 40%;
            background: $blue;
            @media (max-width: 1200px) {
              display: none;
            }
          }
        }
      }
      &__search{
        flex: 1 1 auto;
        @media (max-width: 1052px) {
          flex: 0 0 50px;
          height: 50px;
        }
        @media (max-width: 828px) {
          flex: 1 1 auto;
          height: auto;
        }
        @media (max-width: 640px) {
          flex: 0 0 50px;
          height: 50px;
        }
      }
      &__input{
        background: #FFFFFF;
        position: relative;
        @media (max-width: 1052px) {
          width: 100%;
          height: 100%;
        }
        .search--input{
          padding: 18px 60px 23px 37px;
          border: 1px solid #DDDDDD;
          border-radius: 16px;
          width: 100%;
          font-weight: 400;
          font-size: 18px;
          @media (max-width: 1200px) {
            padding: 15px 50px 18px 30px;
          }
          @media (max-width: 1052px) {
            padding: 0;
            opacity: 0;
          }
          @media (max-width: 828px) {
            padding: 15px 50px 18px 30px;
            opacity: 1;
          }
          @media (max-width: 640px) {
            padding: 0;
            opacity: 0;
          }
        }
        .search--img{
          position: absolute;
          top: 18px;
          right: 20px;
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          overflow: hidden;
          @media (max-width: 1200px) {
            top: 14px;
          }
          @media (max-width: 1052px) {
            padding: 17px;
            width: 50px;
            height: 50px;
            top: 0;
            left: 0;
            border: 1px solid #DDDDDD;
            border-radius: 50%;
          }
          @media (max-width: 828px) {
            right: 20px;
            width: 24px;
            height: 24px;
            padding: 0;
            left: auto;
            top: 14px;
            border: none;
            border-radius: 0;
          }
          @media (max-width: 640px) {
            padding: 17px;
            width: 50px;
            height: 50px;
            top: 0;
            left: 0;
            border: 1px solid #DDDDDD;
            border-radius: 50%;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            @media (max-width: 1052px) {
              width: 16px;
              height: 16px;
            }
            @media (max-width: 828px) {
              width: 100%;
              height: 100%;
            }
            @media (max-width: 640px) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    &__list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: -30px;
      margin-bottom: 110px;
      @media (max-width: 1250px) {
        margin-right: 0;
      }
      @media (max-width: 828px) {
        align-items: center;
        justify-content: center;
      }
    }
    &__item{
      flex: 0 0 calc(33% - 30px);
      margin-right: 30px;
      margin-bottom: 30px;
      @media (max-width: 1250px) {
        margin-right: 0;
        flex: 0 0 47%;
      }
      @media (max-width: 828px) {
        flex: 0 0 100%;
        max-width: 390px;
      }
      &--body{
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &--img{
        width: 100%;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        @media (max-width: 1400px) {
          height: 250px;
        }
        @media (max-width: 1250px) {
          height: 350px;
        }
        @media (max-width: 1200px) {
          height: 280px;
        }
        @media (max-width: 1052px) {
          height: 160px;
        }
        @media (max-width: 828px) {
          height: 350px;
        }
      }
      &--image{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &--information{
        padding: 38px 26px 24px;
        border: 1px solid #DDDDDD;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin-top: -25px;
        z-index: 0;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .list__item--price{
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 6px;
      }
      .list__item--title{
        font-weight: 500;
        font-size: 18px;
        color: $darkgrey;
        margin-bottom: 17px;
        flex: 1 1 auto;
      }
      .list__item--btn{
        display: block;
        width: 91px;
        padding: 11px 28px 17px;
        border: 1px solid #9D9D9D;
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        transition: all 0.2s ease;
        cursor: pointer;
        &:hover{
          background: $green;
          color: #FFFFFF;
        }

        &:disabled {
          opacity: 0.8;
          cursor: no-drop;
        }
      }
    }
  }
}

//Footer===================
.footer{
  border-top: 1px solid $lightgrey;
  &--dropdown{
    display: none;
    border-top: none;
    @media (max-width: 828px) {
      display: inline-block;
      width: 100%;
    }
    .footer--body__contact{
      margin-bottom: 180px;
    }
    .footer--body{
      padding-bottom: 30px !important;
    }
  }
  &--body{
    padding-top: 47px;
    padding-bottom: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 828px) {
      flex-direction: column;
      align-items: start;
    }
    &__contact{
      font-weight: 400;
      font-size: 18px;
      color: $darkgrey;
      @media (max-width: 828px) {
        margin-bottom: 20px;
      }
      &--link{
        font-weight: 700;
        font-size: 30px;
        color: $blue;
        margin-left: 14px;
        @media (max-width: 636px) {
          display: block;
          margin-left: 0;
        }
      }
    }
    &__copyright{
      font-weight: 400;
      font-size: 18px;
      color: #9F9F9F;
    }
  }
}

//Popup ==================
.popup--congrat{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.52);
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  &__body{
    padding: 42px 70px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;
    position: relative;
    overflow-y: auto;
    background: #FFFFFF url("./assets/stars.png") top / contain no-repeat;
    @media (max-width: 828px) {
      padding: 32px 50px;
    }
    @media (max-width: 500px) {
      padding: 21px 30px 29px;
    }
    .close{
      display: block;
      position: absolute;
      top: 25px;
      right: 25px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      &:before, &:after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        background: $grey;
      }
      &:before{
        transform: translateY(-50%) rotate(45deg);
      }
      &:after{
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  &__title{
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    @media (max-width: 828px) {
      font-size: 24px;
    }
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  &__subtitle{
    font-weight: 500;
    font-size: 28px;
    color: $darkgrey;
    margin-bottom: 22px;
    @media (max-width: 828px) {
      font-size: 20px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
    }
    span{
      color: #000000;
    }
  }
  &__image--wrapper{
    width: 433px;
    height: 342px;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 22px;
    @media (max-width: 828px) {
      width: 290px;
      height: 265px;
    }
    @media (max-width: 500px) {
      width: 226px;
      height: 179px;
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__btn{
    display: block;
    padding: 20px 0 24px;
    background: $green;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
    border-radius: 16px;
    @media (max-width: 828px) {
      font-size: 20px;
      padding: 15px 0 21px;
      border-radius: 12px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
      padding: 10px 0 12px;
      border-radius: 8px;
    }
  }
}
.popup--congrat_active{
  display: flex;
}

//Admin================
.admin--profile{
  position:relative;
  margin-right: 54px;
  margin-top: -100px;
  flex: 0 0 640px;
  max-height: 784px;
  @media (max-width: 1615px) {
    flex: 0 0 500px;
    max-height: 620px;
  }
  @media (max-width: 1052px) {
    flex: 0 0 370px;
    max-height: 540px;
    margin-right: 20px;
  }
  @media (max-width: 828px) {
    width: 350px;
    max-height: 520px;
    margin: -100px auto 0;
  }
  @media (max-width: 520px) {
    width: 276px;
    max-height: 350px;
    margin: -100px auto 0;
  }

  &__content{
    width: 100%;
    height: 100%;
    overflow:hidden;
    border-radius: 20px;
}
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__bg{
    position: absolute;
    top: 25px;
    left: -15px;
    width: 100%;
    height: 100%;
    z-index: 1;
    scale: 1.15;
    overflow: hidden;
    &--img{
      width: 100%;
      height:auto;
    }
  }
  &__btn{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 44px;
    margin-bottom: 23px;
    padding: 26px 0 35px;
    font-weight: 700;
    font-size: 75px;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(18.5px);
    border-radius: 20px;
    z-index: 2;
    @media (max-width: 1615px) {
      margin: 0 36px;
      padding: 24px 0 30px;
      margin-bottom: 20px;
      font-size: 60px;
    }
    @media (max-width: 1052px) {
      margin: 0 30px;
      padding: 22px 0 26px;
      margin-bottom: 18px;
      font-size: 50px;
      border-radius: 15px;
    }
    @media (max-width: 828px) {
      margin: 0 24px;
      padding: 15px 0 20px;
      margin-bottom: 15px;
      font-size: 40px;
    }
    @media (max-width: 520px) {
      margin: 0 12px;
      padding: 11px 0 15px;
      margin-bottom: 10px;
      font-size: 32px;
      border-radius: 9px;
    }
  }
}



//WhiteLIst ===============

.wl--form{
  margin-bottom: 500px;
  flex: 1 1 auto;
  @media (max-width: 1052px) {
    margin-bottom: 200px;
  }
  @media (max-width: 828px) {
    margin-bottom: 60px;
  }
  @media (max-width: 520px) {
    margin-bottom: 50px;
  }
  &__body{
    margin-top: 60px;
  }
  &__title{
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 33px;
    @media (max-width: 530px) {
      font-size: 25px;
    }
  }

  &__content{
    width: 100%;
    position: relative;
  }
  &__buttons{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    color: #8E8E8E;
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 1052px) {
      font-size: 18px;
    }
    @media (max-width: 828px) {
      font-size: 20px;
    }
    @media (max-width: 530px) {
      font-size: 16px;
    }
  }
  &__btn{
    margin-right: 23px;
    position: relative;
    padding-bottom: 1px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
    &:before{
      content: "";
      width: 0;
      height: 2px;
      background: #FFFFFF;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.2s ease;
    }
    &_active{
      color: $green;
      &:before{
        background: $green;
        width: 47%;
      }
    }
  }
  &__inputs{
    margin-bottom: 40px;
    font-size: 20px;
    @media (max-width: 530px) {
      font-size: 18px;
    }
    .inputs--block__title{
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 20px;
      @media (max-width: 530px) {
        margin-bottom: 16px;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}

//Input ===========
.input--block__wrapper{
  margin-bottom: 10px;
  font-size: inherit;
  position: relative;
}
.input--block{
  display: inline-block;
  padding: 20px 31px 28px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 16px;
  font-weight: 500;
  font-size: inherit;
  color: #858585;
  width: 100%;
  &:last-child{
    margin-bottom: 0;
  }
}
.input--btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.1s;

  &:not(:disabled) {
    &:hover {
      background-color: $green;
      color: white;
    }
  }

  &:disabled {
    cursor: no-drop;
  }


  &:active {
    transform: translateY(-50%) scale(0.95);
  }
}

.sell-group {
  display: flex;
  width: 100%;
}

//ProfileCollection===================
.profile--collection{
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
  &__content{
    padding: 22px 37px 41px;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DDDDDD;
    @media (max-width: 530px) {
      padding: 19px 28px 28px;
    }
    &--title{
      font-weight: 500;
      font-size: 20px;
      color: $darkgrey;
      margin-bottom: 25px;
    }

  }
  &__top{
    border-bottom: 1px solid $lightgrey;
    &--title{
      font-weight: 600;
      font-size: 16px;
      color: #858585;
      margin-bottom: 5px;
    }
    &--amount{
      font-weight: 600;
      font-size: 28px;
      color: $pink;
      padding-bottom: 18px;
    }
  }
}

//Scrolling======
.collection__wrapper{
  position: relative;
  .prev--btn, .next--btn{
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    cursor: pointer;
    user-select: none;
    @media (max-width: 530px) {
      width: 38px;
      height: 38px;
    }
    .arrow--scroll{
      width: 16px;
      height: 16px;
      object-fit: contain;
      @media (max-width: 530px) {
        width: 13px;
        height: 13px;
      }
      path{
        stroke: $grey;
      }
    }
  }
  .disable{
    pointer-events: none;
    path{
      stroke: $lightgrey !important;
    }
  }
  .prev--btn{
    left: -60px;
  }
  .next--btn{
    right: -60px;
    transform: translateY(-50%) rotate(180deg);
  }
}
.collection--visible{
  margin-top: 31px;
  overflow: hidden;
  @media (max-width: 530px) {
    margin-top: 18px;
  }
  .collection--body__scrolling{
    display: grid;
    width: auto;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit,150px);
    grid-template-rows: repeat(4,auto);
    gap: 24px;
    position: relative;
    transition: left 0.3s ease;

    @media (max-width: 530px) {
      gap: 22px;
      grid-template-columns: repeat(auto-fit,100px);
      grid-template-rows: repeat(4,auto);
    }

    &_short{
      grid-template-rows: repeat(2,auto) !important;
      @media (max-width: 530px) {
        grid-template-rows: repeat(2,auto) !important;
      }
    }
    .collection--item{
      width: 150px;
      @media (max-width: 530px) {
        width: 100px;
      }
      cursor: pointer;
      transition: all 0.1s;
      &:active {
        transform: scale(0.95);
      }
      &.choised {
        opacity: 0.7;
      }
      &__image{
        width: 100%;
        height: 150px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        margin-bottom: 6px;
        @media (max-width: 828px) {
          margin-bottom: 8px;
        }
        @media (max-width: 530px) {
          height: 100px;
          margin-bottom: 5px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
        }
      }
      &__title{
        font-weight: 500;
        font-size: 18px;
        color: $darkgrey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 828px) {
          font-size: 20px;
        }
        @media (max-width: 530px) {
          font-size: 12px;
        }
      }
      &__delisting{
        display: block;
        margin-top: 12px;
        font-weight: 500;
        color: $pink;
        border-radius: 5px;
        width: 100%;
        padding: 9px 0 14px;
        background: #FFE8EC;
        cursor: pointer;
        transition: all 0.2s ease;
        @media (max-width: 530px) {
          padding: 5px 0 7px;
          font-size: 12px;
        }
        &:hover{
          background: $pink;
          color: #FFFFFF;
        }
      }
    }
  }
}


.achievements--block{
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
  position: relative;
  filter: grayscale(1.0);

  &::before {
    width: 100%;
    height: 100%;
    content: "Comming soon";
    position: absolute;
    z-index: 1000;
    background-color: rgba(255, 255, 255, .15);  
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.5);
    transition: all 0.1s;
    transition: color 0.15s;
    color: transparent;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    &::before {
      color: #595959;
      backdrop-filter: blur(3px);
    }
  }

  &__more{
    position: absolute;
    top: 6px;
    right: 6px;
    width: 28px;
    height: 28px;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 9px;
      height: 9px;
      object-fit: contain;
    }
  }
  &__body{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 16px;
    padding: 18px 16px 24px 16px;
    @media (max-width: 530px) {
      padding: 15px 13px 20px 13px;
    }
  }
  &__title{
    font-weight: 500;
    font-size: 20px;
    color: #858585;
    margin-bottom: 22px;
    padding-left: 12px;
  }
  .achievements__list{
    display: flex;
    align-items: center;
    overflow: hidden;
    &--item{
      flex: 1 1 auto;
      margin-right: 6px;
      width: 57px;
      height: 57px;
      @media (max-width: 530px) {
        flex: 0 0 auto;
        width: 50px;
        height: 50px;
        margin-right: 17px;
        &:nth-child(4){
          margin-right: 0;
        }
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .achievement__item--wrapper{
      width: 100%;
      height: 100%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}


//SellForm=====================

.sell--form{
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
  &__inputs{
    flex: 0 0 210px;
    @media (max-width: 530px) {
      flex: 0 0 140px;
      font-size: 14px;
    }
  }
  &__submit{
    flex: 0 0 auto;
    margin-left: auto;
    &--btn{
      display: inline-block;
      padding: 17px 31px 24px;
      background: #E2F5FF;
      border-radius: 10px;
      font-weight: 700;
      font-size: 18px;
      color: $blue;
      cursor: pointer;
      transition: all 0.2s ease ;
      &:hover{
        color: #FFFFFF;
        background: #2890C6;
      }
      @media (max-width: 530px) {
        padding: 20px 18px 21px;
        font-size: 16px;
      }
    }
  }
}

iframe {
  display: none !important;
}

.web3modal-modal-lightbox {
  z-index: 10000 !important;
}


.loader__wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(221, 252, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  @media (min-width: 828px) {
    .loader {
      transform: scale(1.5);
    }
  }
}

.loader {
  --path: #2F3545;
  --dot: #5628EE;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
  &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      display: block;
      background: var(--dot);
      top: 37px;
      left: 19px;
      transform: translate(-18px, -18px);
      animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  svg {
      display: block;
      width: 100%;
      height: 100%;
      rect,
      polygon,
      circle {
          fill: none;
          stroke: var(--path);
          stroke-width: 10px;
          stroke-linejoin: round;
          stroke-linecap: round;
      }
      polygon {
          stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
          stroke-dashoffset: 0;
          animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
      rect {
          stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
          stroke-dashoffset: 0;
          animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
      circle {
          stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
          stroke-dashoffset: 75;
          animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
  }
  &.triangle {
      width: 48px;
      &:before {
          left: 21px;
          transform: translate(-10px, -18px);
          animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
  }
}

@keyframes pathTriangle {
  33% {
      stroke-dashoffset: 74;
  }
  66% {
      stroke-dashoffset: 147;
  }
  100% {
      stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
      transform: translate(0, 0);
  }
  66% {
      transform: translate(10px, -18px);
  }
  100% {
      transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
      stroke-dashoffset: 64;
  }
  50% {
      stroke-dashoffset: 128;
  }
  75% {
      stroke-dashoffset: 192;
  }
  100% {
      stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
      transform: translate(0, 0);
  }
  50% {
      transform: translate(18px, -18px);
  }
  75% {
      transform: translate(0, -36px);
  }
  100% {
      transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
      stroke-dashoffset: 125;
  }
  50% {
      stroke-dashoffset: 175;
  }
  75% {
      stroke-dashoffset: 225;
  }
  100% {
      stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}
