*,*:after,*:before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}
body,html{
  height: 100%;
}

a{
  text-decoration: none;
  color: inherit;
}

ul{
  list-style-type: none;
}

input,textarea{
  outline: none;
}